<template>
    <div class="info-circle">
        <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5ZM9.25 10.5C9.65625 10.5 10 10.8438 10 11.25C10 11.6875 9.65625 12 9.25 12H6.75C6.3125 12 6 11.6875 6 11.25C6 10.8438 6.3125 10.5 6.75 10.5H7.25V8.5H7C6.5625 8.5 6.25 8.1875 6.25 7.75C6.25 7.34375 6.5625 7 7 7H8C8.40625 7 8.75 7.34375 8.75 7.75V10.5H9.25ZM8 6C7.4375 6 7 5.5625 7 5C7 4.46875 7.4375 4 8 4C8.53125 4 9 4.46875 9 5C9 5.5625 8.53125 6 8 6Z"
                fill="#42B24D"/>
        </svg>
        <span v-if="infoText" class="info-circle-text">{{ infoText }}</span>
    </div>
</template>

<script>
export default {
    name:  'InfoCircleIcon',
    props: {
        infoText: {
            type:     String,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.info-circle {
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .info-circle-text {
        visibility: hidden;
        width: 12rem;
        background-color: black;
        color: var(--text-primary);
        text-align: center;
        border-radius: 6px;
        padding: 0.5rem;
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -6rem;
    }

    &:hover {
        .info-circle-text {
            visibility: visible;
        }
    }
}
</style>
