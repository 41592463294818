<template>
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="7" :stroke=color stroke-width="2"/>
        <path d="M20 20L17 17" :stroke=color stroke-linecap="round" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: 'SearchIcon',
    props: {
        color: {
            type: String,
            default: '#929292'
        }
    }
};
</script>

<style scoped>

</style>
